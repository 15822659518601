import React, { useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import NavBar from "src/components/NavBar/NavBar";
import routers from "src/routes/routes";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import styles from "src/styles/App.module.scss";
import { getCookieStorage } from "./helpers/storage";
import { getOrderMode } from "./services/account";
import { getInstrument } from "./services/instrument";
import { getCurrentRegion } from "src/services/user";
import { BaseSocket } from "src/socket/BaseSocket";
import Footer from "./components/Footer/Footer";
import { getMasterData } from "./services/masterdata";
import { getLeverageMargin } from "./services/general";
import { getMasterDataFuture } from "./services/masterdataFuture";
import { TypeTrade, dataRegion } from "./constants/common.constants";
import { Helmet } from "react-helmet";
import { formatPriceRoundDown } from "./helpers/numberFormatter";
import WebBlock from "src/assets/img/web-block.svg";

const App: React.FC<any> = () => {
  const theme = useAppSelector((state) => state.theme.themeMode);
  const ticker = useAppSelector((state) =>
    state.ticker.tickers.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );
  const region = useAppSelector((state) => state.user.region);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  document.documentElement.setAttribute("data-theme", theme);
  const isExpired = getCookieStorage("isExpired");
  const instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );

  const checkRenderSidebar = (path: string) => {
    return !!["/account"].find((i) => path.indexOf(i) !== -1);
  };

  const token = getCookieStorage("access_token");
  useEffect(() => {
    if (location.pathname === "/") {
      // history.push(`/futures/${TypeTrade.USD_M}`);
      window.location.replace(`/futures/${TypeTrade.USD_M}`);
    }
    dispatch(getInstrument());
    dispatch(getMasterData());
    dispatch(getMasterDataFuture());
    dispatch(getCurrentRegion());
    BaseSocket.getInstance().connect();
  }, []);

  useEffect(() => {
    if (
      token &&
      instrument?.id &&
      (isExpired === "false" || isExpired === false)
    ) {
      dispatch(getOrderMode(Number(instrument?.id)));
      dispatch(getLeverageMargin(instrument?.symbol));
    }
  }, [instrument]);
  // console.log("instrument", instrument, instrument.id);

  const tickSize = Number(instrument?.minPriceMovement || "0.01");
  const precision = Math.abs(Math.ceil(Math.log10(Number(tickSize))));

  // tắt tạm đi đỡ lagg
  document.title =
    instrument && ticker
      ? `${formatPriceRoundDown(ticker?.lastPrice || 0, precision)} | ${
          instrument.name || "BTCUSDT"
        } ${" "} ${
          instrument.contractType === TypeTrade.USD_M ? "USDⓈ-M" : "COIN"
        }-Margined Perpetual | Dikex Futures`
      : "Dikex";

  return (
    <div
      id="body"
      className="App"
      style={{
        backgroundColor: location.pathname.includes("webview")
          ? "transparent"
          : "",
      }}
    >
      {/* {instrument && ticker && (
        <Helmet>
          <title>
            {formatPriceRoundDown(ticker?.lastPrice || 0, precision)} | {instrument.name || 'BTCUSDT'}{' '}
            {instrument.contractType === TypeTrade.USD_M ? 'USDⓈ-M' : 'COIN'}-Margined Perpetual | Monas Futures{' '}
          </title>
        </Helmet>
      )} */}

      {region && region.is_block === 0 ? (
        location.pathname.includes("webview") ? (
          <div style={{ height: "100vh" }}>
            <React.Suspense fallback={<div>....Loading</div>}>
              <Switch>
                {Object.keys(routers).map((key) => {
                  //@ts-ignore
                  const route = routers[key];
                  return <route.route key={route.path} {...route} />;
                })}
                <Route path="*" />
              </Switch>
            </React.Suspense>
          </div>
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={styles.navbar}>
              <NavBar />
            </div>
            <div
              className={
                checkRenderSidebar(location.pathname)
                  ? styles.contentFlex
                  : styles.content
              }
              id="main"
            >
              <React.Suspense fallback={<div>....Loading</div>}>
                <Switch>
                  {Object.keys(routers).map((key) => {
                    //@ts-ignore
                    const route = routers[key];
                    return <route.route key={route.path} {...route} />;
                  })}
                  <Route path="*" />
                </Switch>
              </React.Suspense>
            </div>
            {!location.pathname.includes("futures") && (
              <div className={styles.footer}>
                <Footer />
              </div>
            )}
          </div>
        )
      ) : region && region.is_block === 1 ? (
        <img src={WebBlock} style={{ width: "100%", height: "100vh" }} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default App;
