import classnames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import TooltipNumber from 'src/components/cores/Tooltip';
import styles from 'src/features/OrderbookTrade/Orderbook.module.scss';
import { FilterType } from 'src/features/OrderbookTrade/OrderbookTrade';
import { setSelectedOrder } from 'src/features/OrderbookTrade/redux/Orderbook.slice';
import { Instrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';

const cx = classnames.bind(styles);

interface ITable {
  tbody: any[];
  textColor?: string;
  type?: FilterType;
  isExpanded: boolean;
  mobileOverlayDirection?: 'left' | 'right';
  mobileOmitSum?: boolean;
}

const OrderbookTable: React.FC<ITable> = ({
  tbody,
  type,
  isExpanded,
  mobileOverlayDirection = 'right',
  mobileOmitSum = false,
}: ITable) => {
  const dispatch = useDispatch();
  const [isSetScrollBottom, setIsSetScrollBottom] = useState(false);
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const classes = ['table-wrapper'];
  if (isExpanded) {
    classes.push('table-wrapper-expand');
  }
  const currentInstrument: Instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === currentInstrument.symbol);
  const maxFiguresForSize = Number(curTradingRule[0]?.maxFiguresForSize) <= 6 ? curTradingRule[0]?.maxFiguresForSize : 6;
  const isReadyOrderBook = useAppSelector((state) => state.orderbook.isReady);

  useEffect(() => {
    setIsSetScrollBottom(true);
  }, [isExpanded]);

  useEffect(() => {
    if (type === FilterType.asks && bodyRef.current && isSetScrollBottom) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
      setIsSetScrollBottom(false);
    }
  }, [type, bodyRef.current, tbody.length]);

  return (
    <div className={cx(classes)}>
      <table className={cx('table')}>
        <tbody
          ref={bodyRef}
          className={cx(`${isExpanded ? 'scrollbar' : 'non-scrollbar'}`)}
          style={{ flexDirection: type === FilterType.asks ? 'column-reverse' : 'column' }}
        >
          {tbody.map((item, idx) => {
            return (
              <tr
                key={idx}
                className={cx('table-row')}
                onClick={() => {
                  dispatch(setSelectedOrder(item));
                }}
              >
                <td>
                  <div
                    className={
                      mobileOverlayDirection === 'left' ? cx('ordertable-overlay-left') : cx('ordertable-overlay-right')
                    }
                    style={{
                      background: `${type === FilterType.asks ? 'var(--color-bg-ask)' : 'var(--color-bg-bid)'}`,
                      width: `${item[3]}%`,
                    }}
                  />
                  <div className={cx('row')}>
                    <span
                      className={`${type === FilterType.bids ? 'App-positive-value' : 'App-negative-value'}`}
                      style={{ fontSize: '14px' }}
                    >
                      {item[0]}
                    </span>
                    <span className={cx('normal-value')}>
                      {/* {item[1]} */}
                      {item[1] ? (
                        <TooltipNumber defaultValue={item[1]} characters={12} decimal={Number(maxFiguresForSize)} />
                      ) : (
                        item[1]
                      )}
                    </span>
                    {mobileOmitSum ? null : (
                      <span className={cx('normal-value')}>
                        {/* {item[2]} */}
                        {item[2] ? (
                          <TooltipNumber defaultValue={item[2]} characters={12} decimal={Number(maxFiguresForSize)} />
                        ) : (
                          item[2]
                        )}
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrderbookTable;
