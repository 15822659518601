import classnames from 'classnames/bind';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import TooltipNumber from 'src/components/cores/Tooltip';
import styles from 'src/features/OrderbookTrade/Orderbook.module.scss';
import { formatPriceRoundDown } from 'src/helpers/numberFormatter';
import { Instrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';
import {
  IMarketTrade,
} from "src/features/OrderbookTrade/redux/MarketTrade.slice";
import {
  IOrderbook,
} from "src/features/OrderbookTrade/redux/Orderbook.slice";

const cx = classnames.bind(styles);

interface ITable {
  thead?: string[];
  tbody: any[];
  color?: string;
  textColor?: string;
}

const MarketTradeTable: React.FC<ITable> = ({ thead, tbody }: ITable) => {
  const currentInstrument: Instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const isReady = useAppSelector((state) => state.marketTrade.isReady);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === currentInstrument.symbol
  );
    const marketTrade: IMarketTrade[] = useAppSelector(
      (state) => state.marketTrade.marketTrade
    );
    const orderbook: IOrderbook = useAppSelector(
      (state) => state.orderbook.orderbook
    );
    const [data, setData] = useState<IMarketTrade[]>([]);
  // const maxFiguresForPrice = curTradingRule[0]?.maxFiguresForPrice;
  const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) <= 3 ? curTradingRule[0]?.maxFiguresForPrice : 3;
  // const maxFiguresForSize = curTradingRule[0]?.maxFiguresForSize;
  const maxFiguresForSize = Number(curTradingRule[0]?.maxFiguresForSize) <= 6 ? curTradingRule[0]?.maxFiguresForSize : 6;
// console.log("'====>", curTradingRule, maxFiguresForSize);

  useEffect(() => {
    setData(marketTrade);
  }, [marketTrade]);

  return (
    <div className={cx("table-wrapper")}>
      <table className={cx("table")}>
        <thead>
          <tr>
            {thead?.map((item, idx) => {
              return <th key={idx}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody className={cx("scrollbar")}>
          {data.map((item, idx) => {
            return (
              currentInstrument.symbol === item?.symbol && (
                <tr key={idx} className={cx("table-row")}>
                  <td
                    className={
                      item.buyerIsTaker
                        ? "App-positive-value"
                        : "App-negative-value"
                    }
                  >
                    {item.price
                      ? // <TooltipNumber defaultValue={item.price} characters={8} decimal={Number(maxFiguresForPrice)} />
                        formatPriceRoundDown(
                          item.price,
                          Number(maxFiguresForPrice)
                        )
                      : "\u2002"}
                  </td>
                  <td>
                    {item.quantity ? (
                      <TooltipNumber
                        defaultValue={item.quantity}
                        characters={12}
                        decimal={Number(maxFiguresForSize)}
                      />
                    ) : (
                      "\u2002"
                    )}
                  </td>
                  {
                    <td>
                      {item.createdAt
                        ? moment(
                            new Date(item.createdAt).getTime() + 500
                          ).format("HH:mm:ss")
                        : ""}
                    </td>
                  }
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MarketTradeTable;
