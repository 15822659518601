/* eslint-disable max-len */
import classnames from "classnames/bind";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   arrowDown,
//   calculatorIcon,
//   iconTif,
//   iconTransfer,
//   arrowDownActive,
//   arrowUp,
//   preferenceDarkIcon,
//   arrowDownFill,
//   arrowDownFillDark,
//   preferenceDarkIcon2,
//   tifIconDark,
//   calculatorIconDark,
//   transferIconDark,
//   increaseIconDark,
//   reduceIconDark,
//   tifIconDark2,
// } from 'src/assets/icon';
// import filterIcon from 'src/assets/icon/filter.svg';
// import filterIconActive from 'src/assets/icon/setting-active.svg';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { CInput } from "src/components/cores/Input";
import styles from "src/features//Market/Market.module.scss";
import DialogAdjustLeverage from "src/features/Market/components/DialogAdjustLeverage/DialogAdjustLeverage";
import StopModal from "src/features/Market/components/StopModal/StopModal";
import {
  OrderOption,
  OrderSide,
  OrderType,
  STOP_TYPE,
  SYMBOL_TYPE,
  TIME_IN_FORCE,
  WorkingType,
} from "src/features/Market/Constant";
import { getOpenOrder, getAllOpenOrder } from "src/services/order";
import {
  formatValidAmount,
  formatValidPriceNegativeOptinal,
} from "src/features/Market/MarketHelper";
import {
  IOrderbook,
  setSelectedOrder,
  getOrderbook,
} from "src/features/OrderbookTrade/redux/Orderbook.slice";
import { formatRoundDown, isNumber } from "src/helpers/numberFormatter";
import { IOrder } from "src/interfaces/order";
import { Instrument } from "src/services/instrument";
import { ITicker } from "src/services/ticker";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import { CalculatorOrder } from "./Calculator";
import DialogChooseMarginMode, {
  MARGIN_MODE,
} from "./DialogChooseMarginMode/DialogChooseMarginMode";
import { PreferenceModal } from "./Preference/PreferenceModal";
import { TradingRuleModal } from "./TradingRule";
import useOnClickOutside from "src/hooks/useClickOutside";
import axiosInstance from "src/helpers/config";
import { noti } from "src/hooks/useNoti";
import { handleDirect } from "src/features/PositionAndHistory/PositionRecord";
import { useAuth } from "src/hooks/useAuth";
import { OrderConfirmModal } from "./OrderConfirmModal/OrderConfirmModal";
import { Transfer } from "./ManageFunds/Transfer";
import { THEME_MODE } from "src/interfaces/theme";
import { getCookieStorage } from "src/helpers/storage";
import BigNumber from "bignumber.js";
import { SliderRange } from "src/components/SliderRange/SliderRangeMUI";
import TooltipNumber, {
  DECIMAL_FOUR,
  DECIMAL_THREE,
  DECIMAL_TWO,
} from "src/components/cores/Tooltip";
import TooltipText from "src/components/cores/TooltipText";
import { getAvailableBalance } from "src/helpers/balanceCalculator";
import FeeLevel from "./FeeLevel/FeeLevel";
import {
  calculateCostCoinM,
  calculateCostUsdM,
  calculateMaxCoinM,
  calculateMaxUsdM,
} from "../OrderHelper";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import { useTranslation } from "react-i18next";
import {
  BsCaretUpFill,
  BsFillPersonCheckFill,
  BsFillPersonPlusFill,
  BsFillQuestionCircleFill,
  BsCurrencyBitcoin,
  BsCalculatorFill,
  BsCaretDownFill,
  BsBoxes,
} from "react-icons/bs";
import { getAllPosition, getPosition } from "src/services/position";

//regex callback rate
const roundingNumber = (value: number | string, decimal: number) => {
  const numberInput = value + "";
  const numberFormat = numberInput
    .slice(0, 3)
    .replace(/[^0-9.]+|\.(?=.*\.)/gm, "");
  const index = numberFormat.indexOf(".");

  if (index !== -1) {
    if (index === 0) {
      return "";
    } else {
      return numberFormat.slice(0, decimal + index + 1);
    }
  }

  return numberFormat;
};

const convertNumber = (value: number | string, decimal: number) => {
  const numberFormat =
    Math.floor(Number(value) * Math.pow(10, decimal || 5)) /
    Math.pow(10, decimal || 5);
  return numberFormat;
};

const cx = classnames.bind(styles);

// regex
export const getNumberRegex = (precision: number): RegExp => {
  return new RegExp(`^\\d{0,100}.\\d{0,${precision}}$`);
};

export const allowNegativeNumberRegex = (precision: number): RegExp => {
  return new RegExp(`^-?\\d{0,100}.\\d{0,${precision}}$`);
};

const OrderForm: React.FC = () => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const account = useSelector((state: RootState) => state.account.account);
  const orderMode = useSelector((state: RootState) => state.account.orderMode);
  const currentInstrument: Instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const position = useSelector((state: RootState) =>
    state.position.positions?.find((e) => e.symbol === currentInstrument.symbol)
  );
  const ticker: ITicker | undefined = useAppSelector((state) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );
  const allOrders = useAppSelector((state) => state.order.allOrders);
  const loadingPosition = useAppSelector((state) => state.position.loading);
  const loadingOrder = useAppSelector((state) => state.order.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  const selectedOrder = useAppSelector(
    (state) => state.orderbook.selectedOrder
  );
  const orderbook: IOrderbook = useAppSelector(
    (state) => state.orderbook.orderbook
  );
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const curTradingRule = tradingRulesCache.filter(
    (item) => item.symbol === currentInstrument.symbol
  );
  const leverageMarginCache = useAppSelector(
    (state) => state.masterdataFuture.leverageMarginCache
  );
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);
  const minPrice = curTradingRule[0]?.minPrice;
  const maxPrice = curTradingRule[0]?.maxPrice;
  const minOrderPrice = curTradingRule[0]?.minOrderAmount;
  const maxOrderPrice = curTradingRule[0]?.maxOrderAmount;
  const maxFiguresForPrice = curTradingRule[0]?.maxFiguresForPrice;
  const maxFiguresForSize = curTradingRule[0]?.maxFiguresForSize;
  const floorRatio = Number(curTradingRule[0]?.floorRatio) / 100;
  const capRatio = Number(curTradingRule[0]?.limitOrderPrice) / 100;
  const contractMul = Number(currentInstrument.multiplier);

  const availableBalance =
    // currentInstrument.quoteCurrency === 'USD' ? account?.usdAvailableBalance : account?.usdtAvailableBalance;
    getAvailableBalance(
      !isCoinM ? currentInstrument.quoteCurrency : currentInstrument.rootSymbol
    );

  const [price, setPrice] = useState<string>("");
  const [stopPrice, setStopPrice] = useState<string>("");
  const [takeProfit, setTakeProfit] = useState<string>("");
  const [stopLoss, setStopLoss] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [callBackRate, setCallbackRate] = useState<string>("");
  const [activationPrice, setActivationPrice] = useState<string>("");
  const [orderValueBuy, setOrderValueBuy] = useState("");
  const [orderValueSell, setOrderValueSell] = useState("");
  const [maxSellOpen, setMaxSellOpen] = useState("0");
  const [maxBuyOpen, setMaxBuyOpen] = useState("0");
  const [sellOpen, setSellOpen] = useState("0");
  const [buyOpen, setBuyOpen] = useState("0");
  const [order, setOrder] = useState<IOrder>({});

  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const [refSymbol, setRefSymbol] = useState<HTMLImageElement | null>(null);
  const [refTifElm, setRefTifEml] = useState<HTMLImageElement | null>(null);
  const [refStopLossTypeElm, setRefStopLossTypeEml] =
    useState<HTMLImageElement | null>(null);
  const [refTakeProfitTypeElm, setRefTakeProfitTypeEml] =
    useState<HTMLImageElement | null>(null);
  const [refStopPriceTypeElm, setRefStopPriceTypeEml] =
    useState<HTMLImageElement | null>(null);
  const [refActivationPriceTypeElm, setRefActivationPriceTypeEml] =
    useState<HTMLImageElement | null>(null);

  const [orderType, setOrderType] = useState<OrderType>(OrderType.limit);
  const [stopType, setStopType] = useState<OrderType>(OrderType.stopLimit);
  const [symbolType, setSymbolType] = useState<string>("");

  const [timeInForce, setTimeInForce] = useState<string>(
    TIME_IN_FORCE[0].value
  );
  const [stopLossType, setStopLossType] = useState<string>(
    WorkingType[0].value
  );
  const [takeProfitType, setTakeProfitType] = useState<string>(
    WorkingType[0].value
  );
  const [stopPriceType, setStopPriceType] = useState<string>(
    WorkingType[0].value
  );
  const [activationPriceType, setActivationPriceType] = useState<string>(
    WorkingType[0].value
  );
  const [orderOption, setOrderOption] = useState<OrderOption>(OrderOption.none);
  const [isLogined, setIsLogined] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAdjustLeverage, setIsOpenAdjustLeverage] =
    useState<boolean>(false);
  const [leverage, setLeverage] = useState(0);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [isDisableBuy, setIsDisableBuy] = useState(false);
  const [isDisableSell, setIsDisableSell] = useState(false);

  const token = getCookieStorage("access_token");
  const isExpired = getCookieStorage("isExpired");
  const isAuthenticated =
    isExpired === "false" && token && token !== "undefined";
  // &&
  // account[0] &&
  // account[0].id;
  // console.log(isExpired, token, account);

  const el = useRef(null);
  const { logout } = useAuth();

  const [openOrderConfirmModal, setOpenOrderConfirmModal] = useState(false);
  const [orderSide, setOrderSide] = useState(OrderSide.buy);
  const userSetting = useSelector(
    (state: RootState) => state.account.userSetting
  );
  const [indexPrice, setIndexPrice] = useState<number | undefined>(undefined);

  const getIndexPrice = async () => {
    if (currentInstrument.symbol) {
      const res = await axiosInstance.get(`/trading-rules/symbol`, {
        params: {
          symbol: currentInstrument.symbol,
        },
      });
      setIndexPrice(res?.data?.minPriceMovement);
      return res;
    }
  };

  useEffect(() => {
    getIndexPrice();
    if (currentInstrument.rootSymbol) {
      setSymbolType(currentInstrument.rootSymbol);
    }
  }, [currentInstrument]);

  const SYMBOL_LIST = useMemo(() => {
    const SYMBOL_TYPE = [
      {
        value: currentInstrument?.rootSymbol,
        label: currentInstrument?.rootSymbol,
        key: currentInstrument?.rootSymbol,
      },
      {
        value: currentInstrument?.quoteCurrency,
        label: currentInstrument?.quoteCurrency,
        key: currentInstrument?.quoteCurrency,
      },
    ];
    return SYMBOL_TYPE;
  }, [currentInstrument]);

  const calculateMaxOpen = useCallback(() => {
    if (isCoinM) {
      calculateMaxCoinM(
        price,
        orderType,
        currentInstrument,
        allOrders,
        position,
        ticker,
        orderMode,
        orderbook,
        availableBalance,
        contractMul,
        setMaxSellOpen,
        setMaxBuyOpen
      );
    } else
      calculateMaxUsdM(
        price,
        orderType,
        currentInstrument,
        allOrders,
        position,
        ticker,
        orderMode,
        orderbook,
        availableBalance,
        setMaxSellOpen,
        setMaxBuyOpen
      );
  }, [
    allOrders,
    availableBalance,
    contractMul,
    currentInstrument,
    isCoinM,
    orderMode,
    orderType,
    orderbook,
    position,
    price,
    ticker,
  ]);

  const calculateBuySell = useCallback(() => {
    if (quantity.indexOf("%") !== -1) {
      const size = Number(quantity.replace("%", "")) / 100;

      const newMaxSell = Number(maxSellOpen) * size;
      const newMaxBuy = Number(maxBuyOpen) * size;

      setSellOpen(newMaxSell.toString());
      setBuyOpen(newMaxBuy.toString());
    } else {
      setSellOpen("");
      setBuyOpen("");
    }
  }, [maxBuyOpen, maxSellOpen, quantity]);

  useEffect(() => {
    if (isCoinM) {
      calculateCostCoinM(
        price,
        quantity,
        orderType,
        currentInstrument,
        allOrders,
        position,
        ticker,
        orderMode,
        orderbook,
        maxBuyOpen,
        maxSellOpen,
        contractMul,
        setOrderValueSell,
        setOrderValueBuy
      );
    } else
      calculateCostUsdM(
        price,
        quantity,
        orderType,
        currentInstrument,
        allOrders,
        position,
        ticker,
        orderMode,
        orderbook,
        maxBuyOpen,
        maxSellOpen,
        setOrderValueSell,
        setOrderValueBuy,
        symbolType
      );
  }, [
    allOrders,
    contractMul,
    currentInstrument,
    isCoinM,
    maxBuyOpen,
    maxSellOpen,
    orderMode,
    orderType,
    orderbook,
    position,
    price,
    quantity,
    ticker,
    symbolType,
  ]);

  useEffect(() => {
    calculateMaxOpen();
    calculateBuySell();
  }, [calculateBuySell, calculateMaxOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      // setIsLogined((account && Object.keys(account).length > 0) || false);
      setIsLogined(true);
    } else {
      setIsLogined(false);
    }
  }, [isAuthenticated]);

  const restoreState = () => {
    setQuantity("");
    setLeverage(0);
    setPrice(
      ticker?.lastPrice === undefined || loadingTradingRules
        ? ""
        : formatRoundDown(ticker?.lastPrice, Number(maxFiguresForPrice))
    );
    setOrderValueBuy("");
    setOrderValueSell("");
    setStopPrice("");
    setActivationPrice("");
    setTakeProfit("");
    setStopLoss("");
    setCallbackRate("");
    setTimeInForce(TIME_IN_FORCE[0].value);
    setTakeProfitType(WorkingType[0].value);
    setStopLossType(WorkingType[0].value);
    setOrderOption(OrderOption.none);
    setOrder({});
    dispatch(setSelectedOrder(null));
  };

  useEffect(() => {
    restoreState();
  }, [currentInstrument, maxFiguresForPrice, loadingTradingRules]);

  const isGroupOrderLimit = (type: OrderType) => {
    return type === OrderType.limit || type === OrderType.stopLimit;
  };

  const handleChangeOrderType = (type: OrderType) => {
    setQuantity("");
    setLeverage(0);
    setOrderType(type);
    restoreState();
  };

  const renderTypeTooltip = (type: OrderType) => {
    switch (type) {
      case OrderType.limit:
        return `${t("order.tooltip.limit")}`;

      case OrderType.market:
        return `${t("order.tooltip.market")}`;

      case OrderType.stopMarket:
        return `${t("order.tooltip.stop_market")}`;

      case OrderType.stopLimit:
        return `${t("order.tooltip.stop_limit")}`;

      case OrderType.trailingStop:
        return `${t("order.tooltip.trailing_stop")}`;

      case OrderType.postOnly:
        return `${t("order.tooltip.post_only")}`;

      default:
        return `${t("order.tooltip.limit")}`;
    }
  };

  const handleChangeSymbolType = (type: string) => {
    setSymbolType(type);
  };

  const handleChangeStopType = (type: OrderType) => {
    setStopType(type);
    handleChangeOrderType(type);
  };

  const handleChangeTif = (value: any) => {
    setTimeInForce(value);
    setOrder({ ...order, timeInForce: value });
  };

  const handleChangeTakeProfitType = (value: any) => {
    setTakeProfitType(value);
    setOrder({ ...order, takeProfitType: value });
  };

  const handleChangeStopLossType = (value: any) => {
    setStopLossType(value);
    setOrder({ ...order, stopLossType: value });
  };

  const handleStopPriceType = (value: string) => {
    setStopPriceType(value);
    setOrder({ ...order, stopPriceType: value });
  };

  const handleActivationPriceType = (value: string) => {
    setActivationPriceType(value);
    setOrder({ ...order, activationPriceType: value });
  };

  const checkIsDisableButton = useCallback(() => {
    if (orderType === OrderType.trailingStop && activationPrice.length !== 0) {
      let refPrice = 0;
      if (activationPriceType === WorkingType[0].value) {
        refPrice = Number(ticker?.oraclePrice);
      } else refPrice = Number(ticker?.lastPrice);

      if (Number(activationPrice) > refPrice) {
        setIsDisableBuy(true);
        setIsDisableSell(false);
      } else if (Number(activationPrice) < refPrice) {
        setIsDisableBuy(false);
        setIsDisableSell(true);
      } else {
        setIsDisableBuy(false);
        setIsDisableSell(false);
      }
    } else {
      setIsDisableBuy(false);
      setIsDisableSell(false);
    }
  }, [activationPrice, activationPriceType, orderType, ticker]);

  useEffect(() => {
    checkIsDisableButton();
  }, [checkIsDisableButton]);

  const validateLeverage = useCallback(
    (side: OrderSide) => {
      let newPrice = 0;
      let newQuantity = Number(quantity);
      let entryPricePosition = 0;
      let entryPriceOrder = 0;

      if (
        orderType === OrderType.limit ||
        orderType === OrderType.stopLimit ||
        orderType === OrderType.postOnly
      ) {
        newPrice = Number(price);
      } else {
        newPrice = Number(ticker?.lastPrice);
      }

      if (quantity.indexOf("%") !== -1) {
        newQuantity =
          side === OrderSide.buy ? Number(buyOpen) : Number(sellOpen);
      }
      const entryPrice = isCoinM
        ? ticker?.oraclePrice
          ? new BigNumber(newQuantity)
              .times(contractMul)
              .div(ticker?.oraclePrice)
              .toNumber()
          : 0
        : new BigNumber(ticker?.oraclePrice || 0)
            .multipliedBy(newQuantity)
            .toNumber();
      console.log(
        "entryPrice",
        entryPrice,
        ticker?.oraclePrice,
        newQuantity,
        new BigNumber(ticker?.oraclePrice || 0)
          .multipliedBy(newQuantity)
          .toNumber()
      );
      if (position) {
        entryPricePosition = isCoinM
          ? new BigNumber(position.currentQty)
              .abs()
              .times(contractMul)
              .div(position.entryPrice)
              .toNumber()
          : new BigNumber(position.currentQty)
              .abs()
              .multipliedBy(position.entryPrice)
              .toNumber();
      }

      if (allOrders) {
        const initialValue = 0;
        const filteredOrders = allOrders
          .filter((item) => item.symbol === currentInstrument.symbol)
          .map((item) =>
            isCoinM
              ? item?.price
                ? new BigNumber(item?.quantity || "0")
                    .times(contractMul)
                    .div(item?.price)
                    .toNumber()
                : 0
              : new BigNumber(item?.quantity || "0")
                  .times(item?.price || "0")
                  .toNumber()
          );
        entryPriceOrder = filteredOrders.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );
        console.log("filteredOrders", filteredOrders, allOrders);
      }
      if (leverageMarginCache.length !== 0) {
        const totalEntry = new BigNumber(entryPrice)
          .plus(entryPricePosition)
          .plus(entryPriceOrder)
          .toNumber();
        const leveOpenSort = leverageMarginCache.filter(
          (item) =>
            Number(item.min) <= totalEntry &&
            totalEntry <= Number(item.max) &&
            currentInstrument.symbol === item.symbol
        );
        if (Number(orderMode?.leverage) > (leveOpenSort[0]?.maxLeverage || 0)) {
          noti.error({
            title: `${t("order.noti.max_leverage")}`,
          });
          console.log(
            597,
            // t("order.noti.max_leverage"),
            // isCoinM,
            "--",
            entryPrice,
            ticker?.oraclePrice,
            newQuantity,
            new BigNumber(ticker?.oraclePrice || 0)
              .multipliedBy(newQuantity)
              .toNumber()
            // orderMode,
            // entryPrice,
            // "--",
            // totalEntry,'dsa',
            // leveOpenSort,
            // entryPricePosition,
            // entryPriceOrder
          );
          return false;
        }
      }

      return true;
    },
    [
      allOrders,
      buyOpen,
      currentInstrument.symbol,
      leverageMarginCache,
      orderMode?.leverage,
      orderType,
      position,
      price,
      quantity,
      sellOpen,
      ticker?.lastPrice,
      t,
    ]
  );

  const validateOrder = useCallback(
    (side: OrderSide) => {
      switch (orderType) {
        case OrderType.limit:
          if (price.length === 0) {
            noti.error({ title: `${t("order.noti.blank_price")}` });
            console.log(626, t("order.noti.blank_price"));
            return false;
          } else {
            let newMinPrice = 0;
            let newMaxPrice = 0;
            if (side === OrderSide.buy) {
              newMinPrice = Number(minPrice);
              newMaxPrice = Math.min(
                Number(maxPrice),
                Number(ticker?.oraclePrice) * (1 + capRatio)
              );
            } else {
              newMinPrice = Math.max(
                Number(minPrice),
                Number(ticker?.oraclePrice) * (1 - floorRatio)
              );
              newMaxPrice = Number(maxPrice);
            }

            if (!isNaN(newMinPrice) && Number(price) < newMinPrice) {
              noti.error({
                title: `${t("order.noti.min_order", {
                  number: newMinPrice,
                })}`,
              });
              console.log(651, t("order.noti.min_order"), price, newMinPrice);
              return false;
            } else if (!isNaN(newMaxPrice) && Number(price) > newMaxPrice) {
              noti.error({
                title: `${t("order.noti.max_order", {
                  number: newMaxPrice,
                })}`,
              });
              console.log(659, t("order.noti.max_order"));
              return false;
            } else;
          }
          if (orderOption === OrderOption.tpsl) {
            if (side === "BUY") {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) <= Number(price)) ||
                (stopLoss.length !== 0 && Number(stopLoss) >= Number(price))
              ) {
                // noti.error(errorMessage.ORDER_REJECTED_TPSL);
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(
                  672,
                  t("order.noti.order_rejected_tpsl"),
                  takeProfit,
                  stopLoss
                );
                return false;
              }
            } else {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) >= Number(price)) ||
                (stopLoss.length !== 0 && Number(stopLoss) <= Number(price))
              ) {
                // noti.error(errorMessage.ORDER_REJECTED_TPSL);
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(683, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            }
          }

          break;

        case OrderType.market:
          if (orderOption === OrderOption.tpsl) {
            if (side === "BUY") {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) <= Number(ticker?.lastPrice)) ||
                (stopLoss.length !== 0 &&
                  Number(stopLoss) >= Number(ticker?.lastPrice))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(701, t("order.noti.order_rejected_tpsl"));
                return false;
              }

              // if (stopLoss.length !== 0 && Number(stopLoss) >= Number(ticker?.lastPrice)) {
              //   noti.error({title: `${t('order.noti.order_rejected_tpsl')}`});
              //   return false;
              // }
            } else {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) >= Number(ticker?.lastPrice)) ||
                (stopLoss.length !== 0 &&
                  Number(stopLoss) <= Number(ticker?.lastPrice))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(717, t("order.noti.order_rejected_tpsl"));
                return false;
              }
              // if (stopLoss.length !== 0 && Number(stopLoss) <= Number(ticker?.lastPrice)) {
              //   noti.error({ title: `${t('order.noti.order_rejected_tpsl')}` });
              //   return false;
              // }
            }
          }

          if (
            (side === OrderSide.buy && orderbook.asks.length === 0) ||
            (side === OrderSide.sell && orderbook.bids.length === 0)
          ) {
            noti.error({ title: `${t("order.noti.order_rejected")}` });
            console.log(732, t("order.noti.order_rejected"), orderbook);
            return false;
          }
          break;

        case OrderType.stopMarket:
          if (stopPrice.length === 0) {
            noti.error({ title: `${t("order.noti.blank_stop_price")}` });
            console.log(740, t("order.noti.blank_stop_price"));
            return false;
          } else {
            if (Number(stopPrice) < Number(minPrice)) {
              noti.error({
                title: `${t("order.noti.min_stop_price", {
                  number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
                })}`,
              });
              console.log(749, t("order.noti.min_stop_price"));
              return false;
            } else if (Number(stopPrice) > Number(maxPrice)) {
              noti.error({
                title: `${t("order.noti.max_stop_price", {
                  number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
                })}`,
              });
              console.log(757, t("order.noti.max_stop_price"));
              return false;
            } else;

            let upperBound = 0;
            let lowerBound = 0;
            if (stopPriceType === "LAST") {
              const lastPrice = Number(ticker?.lastPrice);
              upperBound = new BigNumber(lastPrice)
                .times(101)
                .dividedBy(100)
                .toNumber();
              lowerBound = new BigNumber(lastPrice)
                .times(99)
                .dividedBy(100)
                .toNumber();
            } else {
              const markPrice = Number(ticker?.oraclePrice);
              upperBound = new BigNumber(markPrice)
                .times(101)
                .dividedBy(100)
                .toNumber();
              lowerBound = new BigNumber(markPrice)
                .times(99)
                .dividedBy(100)
                .toNumber();
            }

            if (
              lowerBound < Number(stopPrice) &&
              upperBound > Number(stopPrice)
            ) {
              noti.error({ title: `${t("order.noti.immediately_trigger")}` });
              console.log(790, t("order.noti.immediately_trigger"));
              return false;
            } else if (upperBound === Number(stopPrice)) {
              noti.error({ title: `${t("order.noti.immediately_trigger")}` });
              console.log(794, t("order.noti.immediately_trigger"));
              return false;
            } else if (lowerBound === Number(stopPrice)) {
              noti.error({ title: `${t("order.noti.immediately_trigger")}` });
              console.log(798, t("order.noti.immediately_trigger"));
              return false;
            } else;
          }
          if (orderOption === OrderOption.tpsl) {
            if (side === "BUY") {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) <= Number(stopPrice)) ||
                (stopLoss.length !== 0 && Number(stopLoss) >= Number(stopPrice))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(810, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            } else {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) >= Number(stopPrice)) ||
                (stopLoss.length !== 0 && Number(stopLoss) <= Number(stopPrice))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(720, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            }
          }

          if (
            (side === OrderSide.buy && orderbook.asks.length === 0) ||
            (side === OrderSide.sell && orderbook.bids.length === 0)
          ) {
            noti.error({ title: `${t("order.noti.order_rejected")}` });
            console.log(831, t("order.noti.order_rejected"), "==", orderbook);
            return false;
          }
          break;

        case OrderType.stopLimit:
          if (stopPrice.length === 0) {
            noti.error({ title: `${t("order.noti.blank_stop_price")}` });
            console.log(839, t("order.noti.blank_stop_price"));
            return false;
          } else {
            if (Number(stopPrice) < Number(minPrice)) {
              noti.error({
                title: `${t("order.noti.min_stop_price", {
                  number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
                })}`,
              });
              console.log(848, t("order.noti.min_stop_price"));
              return false;
            } else if (Number(stopPrice) > Number(maxPrice)) {
              noti.error({
                title: `${t("order.noti.max_stop_price", {
                  number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
                })}`,
              });
              console.log(856, t("order.noti.max_stop_price"));
              return false;
            } else;

            let upperBound = 0;
            let lowerBound = 0;
            if (stopPriceType === "LAST") {
              const lastPrice = Number(ticker?.lastPrice);
              upperBound = new BigNumber(lastPrice)
                .times(101)
                .dividedBy(100)
                .toNumber();
              lowerBound = new BigNumber(lastPrice)
                .times(99)
                .dividedBy(100)
                .toNumber();
            } else {
              const markPrice = Number(ticker?.oraclePrice);
              upperBound = new BigNumber(markPrice)
                .times(101)
                .dividedBy(100)
                .toNumber();
              lowerBound = new BigNumber(markPrice)
                .times(99)
                .dividedBy(100)
                .toNumber();
            }

            if (
              lowerBound < Number(stopPrice) &&
              upperBound > Number(stopPrice)
            ) {
              noti.error({ title: `${t("order.noti.immediately_trigger")}` });
              console.log(889, t("order.noti.immediately_trigger"));
              return false;
            } else if (upperBound === Number(stopPrice)) {
              noti.error({ title: `${t("order.noti.immediately_trigger")}` });
              console.log(893, t("order.noti.immediately_trigger"));
              return false;
            } else if (lowerBound === Number(stopPrice)) {
              noti.error({ title: `${t("order.noti.immediately_trigger")}` });
              console.log(897, t("order.noti.immediately_trigger"));
              return false;
            } else;
          }

          if (price.length === 0) {
            noti.error({ title: `${t("order.noti.blank_price")}` });
            console.log(905, t("order.noti.blank_price"));
            return false;
          } else {
            let newMinPrice = 0;
            let newMaxPrice = 0;
            if (side === OrderSide.buy) {
              newMinPrice = Number(minPrice);
              newMaxPrice = Math.min(
                Number(maxPrice),
                Number(stopPrice) * (1 + capRatio)
              );
            } else {
              newMinPrice = Math.max(
                Number(minPrice),
                Number(stopPrice) * (1 - floorRatio)
              );
              newMaxPrice = Number(maxPrice);
            }
            if (!isNaN(newMinPrice) && Number(price) < newMinPrice / 10) {
              noti.error({
                title: `${t("order.noti.min_order", {
                  number: newMinPrice,
                })}`,
              });
              console.log(929, t("order.noti.min_order"));
              return false;
            } else if (!isNaN(newMaxPrice) && Number(price) > newMaxPrice) {
              noti.error({
                title: `${t("order.noti.max_order", {
                  number: newMaxPrice,
                })}`,
              });
              console.log(937, t("order.noti.max_order"));
              return false;
            } else;
          }

          if (orderOption === OrderOption.tpsl) {
            if (side === "BUY") {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) <= Number(price)) ||
                (stopLoss.length !== 0 && Number(stopLoss) >= Number(price))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(950, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            } else {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) >= Number(price)) ||
                (stopLoss.length !== 0 && Number(stopLoss) <= Number(price))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(960, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            }
          }

          break;

        case OrderType.trailingStop:
          if (Number(callBackRate) === 0) {
            noti.error({ title: `${t("order.noti.blank_callback_rate")}` });
            console.log(971, t("order.noti.blank_callback_rate"));
            return false;
          }
          // if (activationPrice.length === 0) {
          // noti.error({ title: `${t('order.noti.blank_activation_price')}` });
          // return false;
          if (activationPrice.length !== 0) {
            if (Number(activationPrice) < Number(minPrice)) {
              noti.error({
                title: `${t("order.noti.min_activation_price", {
                  number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
                })}`,
              });
              console.log(984, t("order.noti.min_activation_price"));
              return false;
            } else if (Number(activationPrice) > Number(maxPrice)) {
              noti.error({
                title: `${t("order.noti.max_activation_price", {
                  number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
                })}`,
              });
              console.log(992, t("order.noti.max_activation_price"));
              return false;
            } else;
          }
          break;

        case OrderType.postOnly:
          if (price.length === 0) {
            noti.error({ title: `${t("order.noti.blank_price")}` });
            console.log(1001, t("order.noti.blank_price"));
            return false;
          } else {
            let newMinPrice = 0;
            let newMaxPrice = 0;
            if (side === OrderSide.buy) {
              newMinPrice = Number(minPrice);
              newMaxPrice = Math.min(
                Number(maxPrice),
                Number(ticker?.oraclePrice) * (1 + capRatio)
              );
            } else {
              newMinPrice = Math.max(
                Number(minPrice),
                Number(ticker?.oraclePrice) * (1 - floorRatio)
              );
              newMaxPrice = Number(maxPrice);
            }

            if (!isNaN(newMinPrice) && Number(price) < newMinPrice) {
              noti.error({
                title: `${t("order.noti.min_order", {
                  number: newMinPrice,
                })}`,
              });
              console.log(1026, t("order.noti.min_order"));
              return false;
            } else if (!isNaN(newMaxPrice) && Number(price) > newMaxPrice) {
              noti.error({
                title: `${t("order.noti.max_order", {
                  number: newMaxPrice,
                })}`,
              });
              console.log(1033, t("order.noti.max_order"));
              return false;
            } else;
          }
          if (orderOption === OrderOption.tpsl) {
            if (side === "BUY") {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) <= Number(price)) ||
                (stopLoss.length !== 0 && Number(stopLoss) >= Number(price))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(1046, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            } else {
              if (
                (takeProfit.length !== 0 &&
                  Number(takeProfit) >= Number(price)) ||
                (stopLoss.length !== 0 && Number(stopLoss) <= Number(price))
              ) {
                noti.error({ title: `${t("order.noti.order_rejected_tpsl")}` });
                console.log(1056, t("order.noti.order_rejected_tpsl"));
                return false;
              }
            }
          }
          break;

        default:
          break;
      }

      if (quantity.length === 0) {
        noti.error({ title: `${t("order.noti.blank_size")}` });
        console.log(1069, t("order.noti.blank_size"));
        return false;
      } else {
        let newQuantity = quantity;
        if (quantity.indexOf("%") !== -1) {
          newQuantity =
            side === OrderSide.buy
              ? formatValidAmount(buyOpen, currentInstrument)
              : formatValidAmount(sellOpen, currentInstrument);
        }
        if (Number(newQuantity) < Number(minOrderPrice)) {
          noti.error({
            title: `${t("order.noti.min_order_size", {
              number: Number(minOrderPrice).toFixed(Number(minOrderPrice)),
            })}`,
          });
          console.log(
            1085,
            t("order.noti.min_order_size"),
            currentInstrument,
            newQuantity,
            minOrderPrice
          );
          return false;
        } else if (Number(newQuantity) > Number(maxOrderPrice)) {
          noti.error({
            title: `${t("order.noti.max_order_size", {
              number: Number(maxOrderPrice).toFixed(Number(maxFiguresForSize)),
            })}`,
          });
          console.log(1093, t("order.noti.max_order_size"), newQuantity);
          return false;
        } else;
      }

      if (
        (Number(orderValueBuy) > Number(availableBalance) &&
          side === OrderSide.buy) ||
        (Number(orderValueSell) > Number(availableBalance) &&
          side === OrderSide.sell)
      ) {
        noti.error({ title: `${t("order.noti.insufficient_balance")}` });
        console.log(1105, t("order.noti.insufficient_balance"), orderValueBuy);
        return false;
      }

      if (orderOption === OrderOption.tpsl) {
        if (takeProfit.length === 0 && stopLoss.length === 0) {
          noti.error({ title: `${t("order.noti.blank_tpsl")}` });
          console.log(1112, t("order.noti.blank_tpsl"));
          return false;
        }
        if (takeProfit.length !== 0) {
          if (Number(takeProfit) < Number(minPrice)) {
            noti.error({
              title: `${t("order.noti.min_take_profit", {
                number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
              })}`,
            });
            console.log(1122, t("order.noti.min_take_profit"));
            return false;
          } else if (
            takeProfit.length !== 0 &&
            Number(takeProfit) > Number(maxPrice)
          ) {
            noti.error({
              title: `${t("order.noti.max_take_profit", {
                number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
              })}`,
            });
            console.log(1133, t("order.noti.max_take_profit"));
            return false;
          } else;
        }

        if (stopLoss.length !== 0) {
          if (Number(stopLoss) < Number(minPrice)) {
            noti.error({
              title: `${t("order.noti.min_stop_loss", {
                number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
              })}`,
            });
            console.log(1145, t("order.noti.min_stop_loss"));
            return false;
          } else if (Number(stopLoss) > Number(maxPrice)) {
            noti.error({
              title: `${t("order.noti.max_stop_loss", {
                number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
              })}`,
            });
            console.log(1153, t("order.noti.max_stop_loss"));
            return false;
          } else;
        }
      }

      if (orderOption === OrderOption.reduceOnly) {
        if (!position) {
          noti.error({
            title: `${t("order.noti.order_rejected_reduce_only")}`,
          });
          console.log(1164, t("order.noti.order_rejected_reduce_only"));
          return false;
        } else {
          if (
            (Number(position.currentQty) > 0 && side === "BUY") ||
            (Number(position.currentQty) < 0 && side === "SELL") ||
            new BigNumber(position.currentQty).abs().lt(quantity)
          ) {
            noti.error({
              title: `${t("order.noti.order_rejected_reduce_only")}`,
            });
            console.log(1175, t("order.noti.order_rejected_reduce_only"));
            return false;
          }
        }
      }

      if (!validateLeverage(side)) return false;
      return true;
    },
    [
      orderType,
      quantity,
      orderValueBuy,
      availableBalance,
      orderValueSell,
      orderOption,
      validateLeverage,
      price,
      orderbook,
      stopPrice,
      activationPrice,
      minPrice,
      maxPrice,
      ticker,
      capRatio,
      floorRatio,
      maxFiguresForPrice,
      takeProfit,
      stopLoss,
      stopPriceType,
      minOrderPrice,
      maxOrderPrice,
      buyOpen,
      currentInstrument,
      sellOpen,
      maxFiguresForSize,
      position,
      callBackRate,
      t,
    ]
  );

  const createOrder = async (side: OrderSide) => {
    let newQuantity = quantity;
    let newPrice = price;
    let newType = "";
    let tpslType = "";
    let isPostOnly = false;
    let stopCondition = "";
    let takeProfitCondition = "";
    let stopLossCondition = "";

    if (symbolType === currentInstrument?.quoteCurrency) {
      const value =
        Math.floor((Number(quantity) / Number(price)) * 100000) / 100000;
      newQuantity = value.toFixed(5);
    }
    if (quantity.indexOf("%") !== -1) {
      newQuantity =
        side === OrderSide.buy
          ? formatRoundDown(buyOpen, isCoinM ? 0 : Number(maxFiguresForSize))
          : formatRoundDown(sellOpen, isCoinM ? 0 : Number(maxFiguresForSize));
    }

    switch (orderType) {
      case OrderType.limit:
        newType = OrderType.limit;
        tpslType = "";
        break;
      case OrderType.market:
        newType = OrderType.market;
        tpslType = "";
        newPrice = "";
        break;
      case OrderType.stopMarket:
        newType = OrderType.market;
        tpslType = OrderType.stopMarket;
        newPrice = "";
        break;
      case OrderType.stopLimit:
        newType = OrderType.limit;
        tpslType = OrderType.stopLimit;
        break;
      case OrderType.trailingStop:
        newType = OrderType.market;
        tpslType = OrderType.trailingStop;
        newPrice = "";
        break;
      case OrderType.postOnly:
        newType = OrderType.limit;
        tpslType = "";
        isPostOnly = true;
        break;

      default:
        break;
    }
    let activationPriceFinal = activationPrice.trim();
    const lastPriceFinal = formatRoundDown(
      ticker?.lastPrice,
      Number(maxFiguresForPrice)
    );

    if (orderType !== OrderType.trailingStop) {
      if (takeProfit.trim().length !== 0) {
        if (side === "BUY") takeProfitCondition = "GT";
        else takeProfitCondition = "LT";
      }
      if (stopLoss.trim().length !== 0) {
        if (side === "SELL") stopLossCondition = "GT";
        else stopLossCondition = "LT";
      }
      if (stopPrice.trim().length !== 0) {
        if (stopPriceType === "LAST") {
          const lastPrice = Number(ticker?.lastPrice);
          if (Number(stopPrice) > lastPrice) stopCondition = "GT";
          else stopCondition = "LT";
        } else {
          const markPrice = Number(ticker?.oraclePrice);
          if (Number(stopPrice) > markPrice) stopCondition = "GT";
          else stopCondition = "LT";
        }
      }
    } else {
      if (activationPriceFinal.length === 0) {
        activationPriceFinal =
          (activationPriceType === "LAST"
            ? lastPriceFinal
            : ticker?.oraclePrice) || "0";
        if (side === "BUY") {
          stopCondition = "LT";
        } else {
          stopCondition = "GT";
        }
      } else {
        if (activationPriceType === "LAST") {
          const lastPrice = Number(lastPriceFinal);
          if (Number(activationPrice) > lastPrice) stopCondition = "GT";
          else if (Number(activationPrice) < lastPrice) stopCondition = "LT";
          else {
            if (side === "BUY") {
              stopCondition = "LT";
            } else {
              stopCondition = "GT";
            }
          }
        } else {
          const markPrice = Number(ticker?.oraclePrice);
          if (Number(activationPrice) > markPrice) stopCondition = "GT";
          else if (Number(activationPrice) < markPrice) stopCondition = "LT";
          else {
            if (side === "BUY") {
              stopCondition = "LT";
            } else {
              stopCondition = "GT";
            }
          }
        }
      }
    }

    try {
      const params = {
        side: side,
        symbol: currentInstrument.symbol,
        type: newType,
        quantity: newQuantity.trim(),
        price: newPrice.trim(),
        remaining: newQuantity.trim(),
        stopPrice: tpslType === "OrderType.stopLimit" ? "" : stopPrice,
        stopCondition: stopCondition,
        takeProfitCondition: takeProfitCondition,
        stopLossCondition: stopLossCondition,
        takeProfit: takeProfit.trim(),
        stopLoss: stopLoss.trim(),
        trigger:
          orderType === OrderType.trailingStop
            ? activationPriceType
            : stopPriceType,
        takeProfitTrigger: takeProfit.trim().length === 0 ? "" : takeProfitType,
        stopLossTrigger: stopLoss.trim().length === 0 ? "" : stopLossType,
        timeInForce: timeInForce,
        isReduceOnly: orderOption === OrderOption.reduceOnly,
        callbackRate: callBackRate.trim(),
        activationPrice: activationPriceFinal.toString(),
        tpSLType: tpslType,
        tpSLPrice: stopPrice.trim(),
        isPostOnly: isPostOnly,
        contractType: isCoinM ? TypeTrade.COIN_M : TypeTrade.USD_M,
        asset: isCoinM
          ? currentInstrument.rootSymbol
          : currentInstrument.quoteCurrency,
      };

      setIsDisableBuy(true);
      setIsDisableSell(true);
      const res: any = await axiosInstance.post("/order", params);
      setIsDisableBuy(false);
      setIsDisableSell(false);
      if (res?.code === 200 || res?.code === 201) {
        restoreState();
        noti.success({ title: `${t("order.noti.order_submitted")}` });
        // setOpenOrderConfirmModal(false);
        const params = { page: 1, size: RECORDS_PER_PAGE };
        const contractType = location.pathname.includes(TypeTrade.COIN_M)
          ? TypeTrade.COIN_M
          : TypeTrade.USD_M;
        const payload = {
          contractType: contractType,
        };
        dispatch(getOpenOrder({ params, payload }));
        dispatch(getAllOpenOrder(contractType));
        dispatch(getPosition({ ...params, symbol: undefined, contractType }));
        dispatch(getAllPosition(contractType));
      }
      if (res.status === 400 || res.status === 404) {
        noti.error({
          title: res.data?.info?.message,
        });
        setOpenOrderConfirmModal(false);
      }
    } catch (error) {
      noti.error({ title: `${t("order.noti.order_fail")}` });
    }
    setOpenOrderConfirmModal(false);
  };

  const handleCreateOrder = (side: OrderSide) => {
    if (validateOrder(side)) {
      setOrderSide(side);
      switch (orderType) {
        case OrderType.limit:
          if (userSetting.limitOrder === true) {
            setOpenOrderConfirmModal(true);
          } else {
            createOrder(side);
          }
          break;
        case OrderType.postOnly:
          if (userSetting.limitOrder === true) {
            setOpenOrderConfirmModal(true);
          } else {
            createOrder(side);
          }
          break;
        case OrderType.market:
          if (userSetting.marketOrder === true) {
            setOpenOrderConfirmModal(true);
          } else {
            createOrder(side);
          }
          break;
        case OrderType.stopLimit:
          if (userSetting.stopLimitOrder === true) {
            setOpenOrderConfirmModal(true);
          } else {
            createOrder(side);
          }
          break;
        case OrderType.stopMarket:
          if (userSetting.stopMarketOrder === true) {
            setOpenOrderConfirmModal(true);
          } else {
            createOrder(side);
          }
          break;
        case OrderType.trailingStop:
          if (userSetting.traillingStopOrder === true) {
            setOpenOrderConfirmModal(true);
          } else {
            createOrder(side);
          }
          break;
        default:
          break;
      }
    }
    // setOpenOrderConfirmModal(true);
  };

  const getLastPrice = useCallback(async () => {
    const lastPrice = formatRoundDown(
      ticker?.lastPrice,
      Number(maxFiguresForPrice)
    );
    setPrice(lastPrice);
  }, [ticker?.lastPrice, maxFiguresForPrice]);

  const priceRegex = getNumberRegex(
    Number(curTradingRule[0]?.maxFiguresForPrice) || 5
  );

  const priceNegativeOptionalRegex = allowNegativeNumberRegex(
    Number(curTradingRule[0]?.maxFiguresForPrice) || 5
  );
  const amountRegex = getNumberRegex(
    Number(curTradingRule[0]?.maxFiguresForSize) || 2
  );
  const handleChangeAmount = (value: string) => {
    if (!isNumber(value) && value) {
      return;
    }
    value = value?.trim().replace(/^0+\B/, "");
    if (isCoinM) value = value.replace(".", "");
    const s = value?.split(".");
    const beforeDecimal = s ? s[0] : [];
    const decimal = Number(curTradingRule[0]?.maxFiguresForSize);

    if (beforeDecimal.length > 8 || (s && s[1] && s[1].length > decimal)) {
      return;
    }
    if (amountRegex.test(value)) {
      setQuantity(value);
      setOrder({ ...order, quantity: value.toString() });
    } else {
      const amount = formatValidAmount(value, currentInstrument);
      setQuantity(amount);
      setOrder({ ...order, quantity: amount.toString() });
    }
  };

  const handleChangePrice = (
    value: string,
    key: string,
    action: (value: string) => void,
    digitBefore = 8
  ) => {
    if (!isNumber(value) && value) {
      if (key !== "trailValue") {
        return;
      } else if (!new RegExp("[+-]").test(value)) {
        return;
      }
    }
    value = value?.trim().replace(/^0+\B/, "");

    const s = value?.split(".");
    const beforeDecimal = s ? s[0] : [];
    const decimal = Number(curTradingRule[0]?.maxFiguresForPrice);

    if (
      beforeDecimal.length > digitBefore ||
      (s && s[1] && s[1].length > decimal)
    ) {
      return;
    }
    const orderClone: any = { ...order };
    const priceRegexChoosing =
      key === "trailValue" ? priceNegativeOptionalRegex : priceRegex;
    if (priceRegexChoosing.test(value)) {
      action(value);
      orderClone[key] = value.toString();
      setOrder(orderClone);
    } else {
      const price = formatValidPriceNegativeOptinal(
        value,
        currentInstrument,
        key === "trailValue"
      );
      action(price);
      orderClone[key] = price;
      setOrder(orderClone);
    }
  };

  const handleChangeCallbackRate = (value: string) => {
    if (+roundingNumber(value, 1) > 5) {
      setCallbackRate("5");
      setOrder({ ...order, callbackRate: "5" });
    } else {
      setCallbackRate(roundingNumber(value, 1));
      setOrder({ ...order, callbackRate: roundingNumber(value, 1) });
    }
  };

  useEffect(() => {
    if (selectedOrder && isGroupOrderLimit(orderType)) {
      handleChangePrice(selectedOrder[0], "price", setPrice);
    }
  }, [selectedOrder]);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOpenAdjustLeverage = () => {
    setIsOpenAdjustLeverage(!isOpenAdjustLeverage);
  };
  const [openFilter, setOpenFilter] = useState(false);
  const [openPreference, setOpenPreference] = useState(false);
  const [openTradingRules, setOpenTradingRules] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };
  useOnClickOutside(el, () => {
    if (openFilter) setOpenFilter(false);
  });

  const handleAdjustPrice = (
    state: string,
    action: (value: string) => void,
    type: string,
    length: number
  ) => {
    if (type === "up") {
      const newPrice = new BigNumber(Number(state))
        .plus(Number(indexPrice))
        .toNumber();
      // if newPrice exceed max length
      const s = newPrice.toString().split(".");
      const beforeDecimal = s ? s[0] : [];
      if (beforeDecimal.length > length) {
        return;
      }
      action(formatRoundDown(newPrice, Number(maxFiguresForPrice)));
    } else {
      const newPrice = Number(state) - Number(indexPrice);
      action(
        newPrice > 0
          ? formatRoundDown(newPrice, Number(maxFiguresForPrice))
          : "0"
      );
    }
  };

  const adjustPrice = (type: string, priceState: string) => {
    switch (priceState) {
      case "price":
        handleAdjustPrice(price, setPrice, type, 8);
        break;
      case "stopPrice":
        handleAdjustPrice(stopPrice, setStopPrice, type, 9);
        break;
      case "activationPrice":
        handleAdjustPrice(activationPrice, setActivationPrice, type, 9);
        break;
      case "takeProfit":
        handleAdjustPrice(takeProfit, setTakeProfit, type, 9);
        break;
      case "stopLoss":
        handleAdjustPrice(stopLoss, setStopLoss, type, 9);
        break;

      default:
        break;
    }
  };

  const validateQuantity = useCallback(() => {
    let newQuantity = quantity;
    if (symbolType === currentInstrument?.quoteCurrency) {
      const value =
        Math.floor((Number(quantity) / Number(price)) * 100000) / 100000;
      newQuantity = value.toFixed(5);
    }
    if (quantity.indexOf("%") !== -1) {
      newQuantity =
        orderSide === OrderSide.buy
          ? formatRoundDown(buyOpen, isCoinM ? 0 : Number(maxFiguresForSize))
          : formatRoundDown(sellOpen, isCoinM ? 0 : Number(maxFiguresForSize));
    }
    return newQuantity;
  }, [quantity,orderSide]);

  return (
    <>
      <div className={cx("order")}>
        <div className={cx("command")}>
          <button className={cx("open")} onClick={handleClickOpen}>
            {orderMode?.marginMode === MARGIN_MODE.ISOLATE || !isLogined
              ? t("trading.isolated")
              : t("trading.cross")}
          </button>
          <button
            className={cx("close")}
            onClick={handleClickOpenAdjustLeverage}
          >
            {Math.floor(Number(orderMode?.leverage) || 1)}x
          </button>
          <button onClick={handleOpenFilter} className={cx("filter")} ref={el}>
            {openFilter ? (
              <BsFillPersonCheckFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.5 }}
              />
            ) : (
              <BsFillPersonPlusFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.5 }}
              />
            )}

            {openFilter && (
              <div className={cx("filter-option")}>
                {isLogined && (
                  <div
                    onClick={() => isLogined && setOpenPreference(true)}
                    className={cx("option-text")}
                  >
                    {t("tradingRule.preference")}
                  </div>
                )}
                <div
                  onClick={() => setOpenTradingRules(true)}
                  className={cx("option-text")}
                >
                  {t("tradingRule.trading_rules")}
                </div>
              </div>
            )}
          </button>
        </div>

        {openPreference && (
          <PreferenceModal
            open={openPreference}
            handleClose={() => setOpenPreference(false)}
          />
        )}
        <OrderConfirmModal
          open={openOrderConfirmModal}
          side={orderSide}
          orderType={orderType}
          stopPrice={stopPrice}
          price={price}
          quantity={validateQuantity()}
          // quantity={
          //   quantity.indexOf("%") !== -1
          //     ? orderSide === OrderSide.buy
          //       ? formatRoundDown(
          //           buyOpen,
          //           isCoinM ? 0 : Number(maxFiguresForSize)
          //         )
          //       : formatRoundDown(
          //           sellOpen,
          //           isCoinM ? 0 : Number(maxFiguresForSize)
          //         )
          //     : quantity
          // }
          callBackRate={callBackRate}
          activationPrice={activationPrice}
          takeProfit={takeProfit}
          stopLoss={stopLoss}
          takeProfitType={takeProfitType}
          stopLossType={stopLossType}
          isCoinM={isCoinM}
          handleClose={() => setOpenOrderConfirmModal(false)}
          createOrder={() => createOrder(orderSide)}
        />

        {/* Dialog Choose Margin Mode */}
        {isOpen && (
          <DialogChooseMarginMode
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            marginMode={orderMode?.marginMode}
            leverage={Math.floor(Number(orderMode?.leverage))}
          />
        )}

        {/* Dialog Adjust Leverage */}
        {isOpenAdjustLeverage && (
          <DialogAdjustLeverage
            isOpenAdjustLeverage={isOpenAdjustLeverage}
            setIsOpenAdjustLeverage={setIsOpenAdjustLeverage}
            leverage={Math.floor(Number(orderMode?.leverage))}
            marginMode={orderMode?.marginMode}
          />
        )}

        {/* Tab Order Type */}
        <div className={cx("type-order")}>
          <div
            className={cx(
              "type-item",
              orderType === OrderType.limit ? "type-item-active" : ""
            )}
            onClick={() => handleChangeOrderType(OrderType.limit)}
          >
            {t("order.limit")}
          </div>

          <div
            className={cx(
              "type-item",
              orderType === OrderType.market ? "type-item-active" : ""
            )}
            onClick={() => handleChangeOrderType(OrderType.market)}
          >
            {t("order.market")}
          </div>

          <div
            className={cx("type-item-stop")}
            onClick={() => handleChangeOrderType(stopType)}
          >
            <span
              className={cx(
                "type-item",
                orderType !== OrderType.limit && orderType !== OrderType.market
                  ? "type-item-active"
                  : ""
              )}
            >
              {t(`order.${STOP_TYPE.find((e) => e.value === stopType)?.key}`)}
            </span>
            <span
              onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                setRefEml(event.currentTarget);
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {Boolean(refElm) ? (
                <BsCaretDownFill
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  // style={{ zoom: 1.5 }}
                />
              ) : (
                <BsCaretUpFill
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  // style={{ zoom: 1.5 }}
                />
              )}
            </span>
          </div>
          <div className={cx("tooltip")}>
            <TooltipText text={renderTypeTooltip(orderType)}>
              <BsFillQuestionCircleFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.25 }}
              />
            </TooltipText>
          </div>
        </div>
        {/* Order Form */}
        <div className={cx("order-detail")}>
          <div className={cx("order-detail-item")}>
            <div className={cx("order-balance")}>
              <span>{t("order.available_c")}</span>
              {isLogined && !loadingPosition && !loadingOrder ? (
                <TooltipNumber
                  defaultValue={availableBalance}
                  decimal={DECIMAL_THREE}
                  characters={20}
                  customText={cx("value-span")}
                  symbol={
                    isCoinM
                      ? currentInstrument.rootSymbol
                      : currentInstrument.quoteCurrency
                  }
                />
              ) : (
                <span>
                  -{" "}
                  {isCoinM
                    ? currentInstrument.rootSymbol
                    : currentInstrument.quoteCurrency}
                </span>
              )}

              {isLogined && (
                <BsCurrencyBitcoin
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 1.25 }}
                />
              )}
            </div>
            <BsCalculatorFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.35, cursor: "pointer", marginRight: "3px" }}
              onClick={() => setOpenCalculator(!openCalculator)}
            />
          </div>
          {(orderType === OrderType.stopMarket ||
            orderType === OrderType.stopLimit) && (
            <div className={cx("order-price")}>
              <CInput
                classNamePrefix={cx("input")}
                type="text"
                placeholder={`${t("order.stop_price")}`}
                size="sm"
                outline={false}
                valueHandSet={stopPrice}
                onChange={(value: string): void => {
                  handleChangePrice(value, "stopPrice", setStopPrice);
                }}
              />
              <div style={{ display: "flex" }}>
                <div
                  className={cx("tif-select")}
                  onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                    setRefStopPriceTypeEml(event.currentTarget);
                  }}
                >
                  <span
                    className={cx(
                      Boolean(refStopPriceTypeElm) === true
                        ? "label-active"
                        : "label"
                    )}
                  >
                    {t(
                      WorkingType.find((e) => e.value === stopPriceType)?.label
                    )}
                  </span>
                  <BsCaretDownFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    style={{ zoom: 0.75 }}
                  />
                </div>
                <div className={cx("change-price")}>
                  {/* <img
                    onClick={() => adjustPrice("up", "stopPrice")}
                    src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                    className={cx("arrow-up")}
                  />
                  <img
                    onClick={() => adjustPrice("down", "stopPrice")}
                    src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                    className={cx("arrow-down")}
                  /> */}
                  <BsCaretUpFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    // style={{ zoom: 1.5 }}
                    onClick={() => adjustPrice("up", "stopPrice")}
                  />
                  <BsCaretDownFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    // style={{ zoom: 1.5 }}
                    onClick={() => adjustPrice("down", "stopPrice")}
                  />
                </div>
              </div>
            </div>
          )}

          {(orderType === OrderType.limit ||
            orderType === OrderType.stopLimit ||
            orderType === OrderType.postOnly) && (
            <div className={cx("order-price")}>
              <CInput
                classNamePrefix={cx("input")}
                type="text"
                placeholder={t("order.price")}
                size="sm"
                outline={false}
                // valueHandSet={price}
                valueHandSet={price ? String(Math.round(Number(price)*100)/100): ""}
                onChange={(value: string): void => {
                  handleChangePrice(value, "price", setPrice);
                }}
              />
              <div className={cx("last-price")}>
                <TooltipText text={t("order.tooltip.last")}>
                  <div
                    className={cx("last")}
                    onClick={() => {
                      getLastPrice();
                    }}
                  >
                    {t("order.last")}
                  </div>
                </TooltipText>
                <div className={cx("last-text")}>
                  {currentInstrument.quoteCurrency}
                </div>
                <div className={cx("change-price")}>
                  <BsCaretUpFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    // style={{ zoom: 1.5 }}
                    onClick={() => adjustPrice("up", "price")}
                  />
                  <BsCaretDownFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    // style={{ zoom: 1.5 }}
                    onClick={() => adjustPrice("down", "price")}
                  />
                </div>
              </div>
            </div>
          )}

          {orderType === OrderType.trailingStop && (
            <div>
              <div className={cx("callback-rate-container")}>
                <div className={cx("order-price")}>
                  <CInput
                    classNamePrefix={cx("input")}
                    type="text"
                    placeholder={t("order.callback_rate")}
                    size="sm"
                    outline={false}
                    valueHandSet={callBackRate}
                    onChange={(value: string) => {
                      handleChangeCallbackRate(value);
                    }}
                  />
                  <div className={cx("token-symbol")}>%</div>
                </div>
                <div
                  className={cx("rate")}
                  onClick={() => setCallbackRate("1")}
                >
                  1 %
                </div>
                <div
                  className={cx("rate")}
                  onClick={() => setCallbackRate("2")}
                >
                  2 %
                </div>
              </div>
              <div className={cx("order-price")}>
                <CInput
                  classNamePrefix={cx("input")}
                  type="text"
                  placeholder={t("order.activation_price")}
                  size="sm"
                  outline={false}
                  valueHandSet={activationPrice}
                  onChange={(value: string): void => {
                    handleChangePrice(
                      value,
                      "activationPrice",
                      setActivationPrice
                    );
                  }}
                />
                <div style={{ display: "flex" }}>
                  <div
                    className={cx("tif-select")}
                    onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                      setRefActivationPriceTypeEml(event.currentTarget);
                    }}
                  >
                    <span
                      className={cx(
                        Boolean(refActivationPriceTypeElm) === true
                          ? "label-active"
                          : "label"
                      )}
                    >
                      {t(
                        WorkingType.find((e) => e.value === activationPriceType)
                          ?.label
                      )}
                    </span>
                    <BsCaretDownFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      style={{ zoom: 0.75 }}
                    />
                  </div>
                  <div className={cx("change-price")}>
                    {/* <img
                      onClick={() => adjustPrice("up", "activationPrice")}
                      src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                      className={cx("arrow-up")}
                    />
                    <img
                      onClick={() => adjustPrice("down", "activationPrice")}
                      src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                      className={cx("arrow-down")}
                    /> */}
                    <BsCaretUpFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      // style={{ zoom: 1.5 }}
                      onClick={() => adjustPrice("up", "activationPrice")}
                    />
                    <BsCaretDownFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      // style={{ zoom: 1.5 }}
                      onClick={() => adjustPrice("down", "activationPrice")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={cx("order-price", "last-order-price")}>
            <CInput
              classNamePrefix={cx("input")}
              type="text"
              placeholder={
                symbolType === currentInstrument.quoteCurrency
                  ? t("orderBook.total")
                  : t("order.size") +
                    (isCoinM ? ` (1 Cont = ${contractMul} USD)` : "")
              }
              size="sm"
              outline={false}
              valueHandSet={quantity}
              onChange={(value: string): void => {
                handleChangeAmount(value);
                setLeverage(0);
              }}
              onfocus={() => {
                setLeverage(0);
                quantity.includes("%") && setQuantity("");
              }}
            />

            {isCoinM ? (
              <div className={cx("token-symbol")}>Cont</div>
            ) : (
              <span
                onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  setRefSymbol(event.currentTarget);
                }}
                style={{ display: "flex", alignItems: "center" }}
                className={cx("addonAfter")}
              >
                <div className={cx("token-symbol")}>{symbolType}</div>
                {Boolean(refSymbol) ? (
                  <BsCaretDownFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    // style={{ zoom: 1.5 }}
                  />
                ) : (
                  <BsCaretUpFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    // style={{ zoom: 1.5 }}
                  />
                )}
              </span>
            )}

            {/* <div className={cx("token-symbol")}>
              {isCoinM ? "Cont" : currentInstrument.rootSymbol}
            </div> */}
          </div>

          <div className={cx("order-quantity")}>
            {currentInstrument.rootSymbol !== symbolType &&
              quantity.indexOf("%") === -1 && (
                <span>
                  ≈ {convertNumber(Number(quantity) / Number(price), 5)}{" "}
                  {currentInstrument.rootSymbol}
                </span>
              )}
          </div>

          <SliderRange
            value={leverage}
            handleRange={(value: number) => {
              setQuantity(value ? value + "%" : "");
              setLeverage(value);
            }}
            type="size"
          />
          {isLogined ? (
            <>
              {" "}
              <div className={cx("cost")}>
                <div className={cx("cost-item")}>
                  <span className={cx("label-span")}>
                    {t("order.buy")}&nbsp;
                  </span>
                  <TooltipNumber
                    defaultValue={buyOpen}
                    decimal={isCoinM ? 0 : DECIMAL_TWO}
                    characters={10}
                    customText={cx("value-span")}
                    symbol={isCoinM ? "Cont" : currentInstrument.rootSymbol}
                  />
                </div>
                <div className={cx("cost-item")}>
                  <span className={cx("label-span")}>
                    {t("order.sell")}&nbsp;
                  </span>
                  <TooltipNumber
                    defaultValue={sellOpen}
                    decimal={isCoinM ? 0 : DECIMAL_TWO}
                    characters={10}
                    customText={cx("value-span")}
                    symbol={isCoinM ? "Cont" : currentInstrument.rootSymbol}
                  />
                </div>
              </div>
              <div style={{ paddingTop: "14px" }}>
                {orderType !== OrderType.trailingStop && (
                  <div
                    onClick={() => {
                      if (orderOption != OrderOption.reduceOnly) {
                        if (orderOption != OrderOption.tpsl) {
                          setOrderOption(OrderOption.tpsl);
                          handleChangePrice("", "price", setTakeProfit);
                          handleChangePrice("", "price", setStopLoss);
                        } else {
                          setOrderOption(OrderOption.none);
                          handleChangePrice("", "price", setTakeProfit);
                          handleChangePrice("", "price", setStopLoss);
                        }
                      }
                    }}
                    className={cx(
                      "container",
                      `${
                        orderOption === OrderOption.reduceOnly ? "disabled" : ""
                      }`
                    )}
                  >
                    <input
                      type="checkbox"
                      id="tpsl"
                      name="tpsl"
                      value="tpsl"
                      checked={orderOption === OrderOption.tpsl}
                      disabled={orderOption === OrderOption.reduceOnly}
                      readOnly
                    />
                    <span className={cx("checkmark")}></span>

                    <TooltipText text={t("order.tooltip.tpsl")}>
                      <span className={cx("label-checkbox")}>
                        {t("order.tpsl")}
                      </span>
                    </TooltipText>
                  </div>
                )}

                {orderOption === OrderOption.tpsl && (
                  <div>
                    <div className={cx("order-price")}>
                      <CInput
                        classNamePrefix={cx("input")}
                        type="text"
                        placeholder={t("order.take_profit")}
                        size="sm"
                        outline={false}
                        valueHandSet={takeProfit}
                        onChange={(value: string): void => {
                          handleChangePrice(value, "price", setTakeProfit);
                        }}
                      />
                      <div style={{ display: "flex" }}>
                        <div
                          className={cx("tif-select")}
                          onClick={(
                            event: React.MouseEvent<HTMLImageElement>
                          ) => {
                            setRefTakeProfitTypeEml(event.currentTarget);
                          }}
                        >
                          <span
                            className={cx(
                              Boolean(refTakeProfitTypeElm) === true
                                ? "label-active"
                                : "label"
                            )}
                          >
                            {t(
                              WorkingType.find(
                                (e) => e.value === takeProfitType
                              )?.label
                            )}
                          </span>
                          <BsCaretDownFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            style={{ zoom: 0.75 }}
                          />
                        </div>
                        <div className={cx("change-price")}>
                          {/* <img
                            onClick={() => adjustPrice("up", "takeProfit")}
                            src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                            className={cx("arrow-up")}
                          />
                          <img
                            onClick={() => adjustPrice("down", "takeProfit")}
                            src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                            className={cx("arrow-down")}
                          /> */}
                          <BsCaretUpFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            // style={{ zoom: 1.5 }}
                            onClick={() => adjustPrice("up", "takeProfit")}
                          />
                          <BsCaretDownFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            // style={{ zoom: 1.5 }}
                            onClick={() => adjustPrice("down", "takeProfit")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={cx("order-price")}>
                      <CInput
                        classNamePrefix={cx("input")}
                        type="text"
                        placeholder={t("order.stop_loss")}
                        size="sm"
                        outline={false}
                        valueHandSet={stopLoss}
                        onChange={(value: string): void => {
                          handleChangePrice(value, "price", setStopLoss);
                        }}
                      />
                      <div style={{ display: "flex" }}>
                        <div
                          className={cx("tif-select")}
                          onClick={(
                            event: React.MouseEvent<HTMLImageElement>
                          ) => {
                            setRefStopLossTypeEml(event.currentTarget);
                          }}
                        >
                          <span
                            className={cx(
                              Boolean(refStopLossTypeElm) === true
                                ? "label-active"
                                : "label"
                            )}
                          >
                            {t(
                              WorkingType.find((e) => e.value === stopLossType)
                                ?.label
                            )}
                          </span>
                          <BsCaretDownFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            style={{ zoom: 0.75 }}
                          />
                        </div>
                        <div className={cx("change-price")}>
                          {/* <img
                            onClick={() => adjustPrice("up", "stopLoss")}
                            src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                            className={cx("arrow-up")}
                          />
                          <img
                            onClick={() => adjustPrice("down", "stopLoss")}
                            src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                            className={cx("arrow-down")}
                          /> */}
                          <BsCaretUpFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            // style={{ zoom: 1.5 }}
                            onClick={() => adjustPrice("up", "stopLoss")}
                          />
                          <BsCaretDownFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            // style={{ zoom: 1.5 }}
                            onClick={() => adjustPrice("down", "stopLoss")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className={cx("reduce-flex")}>
                  <div
                    onClick={() => {
                      if (orderOption === OrderOption.reduceOnly) {
                        setOrderOption(OrderOption.none);
                        handleChangePrice("", "price", setTakeProfit);
                        handleChangePrice("", "price", setStopLoss);
                      } else {
                        setOrderOption(OrderOption.reduceOnly);
                        handleChangePrice("", "price", setTakeProfit);
                        handleChangePrice("", "price", setStopLoss);
                      }
                      setOrder({ ...order, isHidden: false });
                    }}
                    className={cx("container")}
                  >
                    <input
                      type="checkbox"
                      id="reduceOnly"
                      name="reduceOnly"
                      value="reduceOnly"
                      checked={orderOption === OrderOption.reduceOnly}
                      readOnly
                    />
                    <span className={cx("checkmark")}></span>
                    <TooltipText text={t("order.tooltip.reduce_only")}>
                      <span className={cx("label-checkbox")}>
                        {t("order.reduce_only")}
                      </span>
                    </TooltipText>
                  </div>
                  {(orderType === OrderType.stopLimit ||
                    orderType === OrderType.limit) && (
                    <div className={cx("tif")}>
                      <TooltipText
                        text={
                          <p>
                            <p>{t("order.tooltip.tif")}</p>
                            <p>• {t("order.tooltip.gtc")}</p>
                            <p>• {t("order.tooltip.ioc")}</p>
                            <p>• {t("order.tooltip.fok")}</p>
                          </p>
                        }
                      >
                        <BsBoxes
                          fill={
                            theme === THEME_MODE.LIGHT ? "black" : "lightgray"
                          }
                          style={{ cursor: "pointer", zoom: 0.75 }}
                        />
                      </TooltipText>
                      <div className={cx("tif-label")}>TIF</div>
                      <div
                        className={cx("tif-select")}
                        onClick={(
                          event: React.MouseEvent<HTMLImageElement>
                        ) => {
                          setRefTifEml(event.currentTarget);
                        }}
                      >
                        <span>
                          {
                            TIME_IN_FORCE.find((e) => e.value === timeInForce)
                              ?.label
                          }
                        </span>
                        <BsCaretDownFill
                          fill={
                            theme === THEME_MODE.LIGHT ? "black" : "lightgray"
                          }
                          // style={{ zoom: 1.3 }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={cx("command")}>
                <button
                  className={cx("buy", "open")}
                  onClick={() => {
                    handleCreateOrder(OrderSide.buy);
                  }}
                  disabled={isDisableBuy}
                  onDoubleClick={(e) => e.preventDefault()}
                >
                  {t("order.buy_long")}
                </button>
                <button
                  className={cx("sell", "open")}
                  onClick={() => {
                    handleCreateOrder(OrderSide.sell);
                  }}
                  disabled={isDisableSell}
                  onDoubleClick={(e) => e.preventDefault()}
                >
                  {t("order.sell_short")}
                </button>
              </div>
              <div className={cx("bottom")}>
                <div className={cx("cost", "cost-mb")}>
                  <div className={cx("cost-item")}>
                    <TooltipText text={t("order.tooltip.cost")}>
                      <span className={cx("label-span")}>
                        {t("order.cost")}&nbsp;
                      </span>
                    </TooltipText>

                    <TooltipNumber
                      defaultValue={orderValueBuy}
                      decimal={isCoinM ? DECIMAL_FOUR : DECIMAL_TWO}
                      characters={7}
                      customText={cx("value-span")}
                      symbol={
                        isCoinM
                          ? currentInstrument.rootSymbol
                          : currentInstrument.quoteCurrency
                      }
                    />
                  </div>
                  <div className={cx("cost-item")}>
                    <TooltipText text={t("order.tooltip.cost")}>
                      <span className={cx("label-span")}>
                        {t("order.cost")}&nbsp;
                      </span>
                    </TooltipText>

                    <TooltipNumber
                      defaultValue={orderValueSell}
                      decimal={isCoinM ? DECIMAL_FOUR : DECIMAL_TWO}
                      characters={7}
                      customText={cx("value-span")}
                      symbol={
                        isCoinM
                          ? currentInstrument.rootSymbol
                          : currentInstrument.quoteCurrency
                      }
                    />
                  </div>
                </div>

                <div className={cx("cost")}>
                  <div className={cx("cost-item")}>
                    <span className={cx("label-span")}>
                      {t("order.max")}&nbsp;
                    </span>
                    <TooltipNumber
                      defaultValue={maxBuyOpen}
                      decimal={isCoinM ? 0 : DECIMAL_TWO}
                      characters={9}
                      customText={cx("value-span")}
                      symbol={isCoinM ? "Cont" : currentInstrument.rootSymbol}
                    />
                  </div>
                  <div className={cx("cost-item")}>
                    <span className={cx("label-span")}>
                      {t("order.max")}&nbsp;
                    </span>
                    <TooltipNumber
                      defaultValue={maxSellOpen}
                      decimal={isCoinM ? 0 : DECIMAL_TWO}
                      characters={9}
                      customText={cx("value-span")}
                      symbol={isCoinM ? "Cont" : currentInstrument.rootSymbol}
                    />
                  </div>
                </div>

                <div className={cx("limit-flex")}>
                  <FeeLevel
                    title={t("order.fee_level")}
                    makerFee={Number(currentInstrument.makerFee)}
                    takerFee={Number(currentInstrument.takerFee)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              <button
                className={cx("open", "sign-up")}
                onClick={async () => {
                  await logout();
                  handleDirect("signup");
                }}
              >
                {t("order.signup")}
              </button>
              <button
                className={cx("open", "login")}
                onClick={async () => {
                  await logout();
                  handleDirect("login");
                }}
              >
                {t("order.login")}
              </button>
            </div>
          )}
        </div>
      </div>
      <StopModal
        open={Boolean(refSymbol)}
        handleClose={() => setRefSymbol(null)}
        refElm={refSymbol}
        handleChooseOption={handleChangeSymbolType}
        selectedValue={symbolType}
        option={SYMBOL_LIST}
      />

      <StopModal
        open={Boolean(refElm)}
        handleClose={() => setRefEml(null)}
        refElm={refElm}
        handleChooseOption={handleChangeStopType}
        selectedValue={stopType}
        option={STOP_TYPE}
      />
      <StopModal
        open={Boolean(refTifElm)}
        handleClose={() => setRefTifEml(null)}
        refElm={refTifElm}
        handleChooseOption={handleChangeTif}
        selectedValue={timeInForce}
        option={TIME_IN_FORCE}
      />
      <StopModal
        open={Boolean(refTakeProfitTypeElm)}
        handleClose={() => setRefTakeProfitTypeEml(null)}
        refElm={refTakeProfitTypeElm}
        handleChooseOption={handleChangeTakeProfitType}
        selectedValue={takeProfitType}
        option={WorkingType}
      />
      <StopModal
        open={Boolean(refStopLossTypeElm)}
        handleClose={() => setRefStopLossTypeEml(null)}
        refElm={refStopLossTypeElm}
        handleChooseOption={handleChangeStopLossType}
        selectedValue={stopLossType}
        option={WorkingType}
      />

      <StopModal
        open={Boolean(refStopPriceTypeElm)}
        handleClose={() => setRefStopPriceTypeEml(null)}
        refElm={refStopPriceTypeElm}
        handleChooseOption={handleStopPriceType}
        selectedValue={stopPriceType}
        option={WorkingType}
      />
      <StopModal
        open={Boolean(refActivationPriceTypeElm)}
        handleClose={() => setRefActivationPriceTypeEml(null)}
        refElm={refActivationPriceTypeElm}
        handleChooseOption={handleActivationPriceType}
        selectedValue={activationPriceType}
        option={WorkingType}
      />
      {openCalculator && (
        <CalculatorOrder handleClose={() => setOpenCalculator(false)} />
      )}
      {openTransfer && (
        <Transfer
          isOpen={openTransfer}
          onClose={() => setOpenTransfer(false)}
          defaultCoinSelect={
            isCoinM
              ? currentInstrument.rootSymbol
              : currentInstrument.quoteCurrency
          }
        />
      )}
      {openTradingRules && (
        <TradingRuleModal
          open={openTradingRules}
          handleClose={() => setOpenTradingRules(false)}
        />
      )}
    </>
  );
};

export default OrderForm;
